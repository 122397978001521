import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';


@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.css']
})
export class WorksComponent implements OnInit {

  ngOnInit(): void {
    // window.location.reload();
  }
  album: any = [];
  albumClone;
  constructor(private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig, @Inject(DOCUMENT) private doc, private elementRef: ElementRef) {


    // let link1: HTMLLinkElement = this.doc.createElement('link');
    // link1.setAttribute('rel', 'amphtml');
    // link1.setAttribute('href', '../assets/css/bootstrap.min.css');
    // this.doc.head.appendChild(link1);

    // let link: HTMLLinkElement = this.doc.createElement('link');
    // link.setAttribute('rel', 'amphtml');
    // link.setAttribute('href', '../assets/css/owl.carousel.css');
    // this.doc.head.appendChild(link);


    // var s14 = document.createElement("script");
    // s14.type = "text/javascript";
    // s14.src = "../assets/js/custom.js";
    // this.elementRef.nativeElement.appendChild(s14);

    // var s1 = document.createElement("script");
    // s1.type = "text/javascript";
    // s1.src = "../assets/js/isotope.pkgd.min.js.js";
    // this.elementRef.nativeElement.appendChild(s1);

    // var s11 = document.createElement("script");
    // s11.type = "text/javascript";
    // s11.src = "../assets/js/jquery.counterup.min.js.js";
    // this.elementRef.nativeElement.appendChild(s11);

    // var s12 = document.createElement("script");
    // s12.type = "text/javascript";
    // s12.src = "../assets/js/jquery.magnific-popup.min.js.js";
    // this.elementRef.nativeElement.appendChild(s12);





    // _lightboxConfig.fadeDuration = 1;

    this.cardsPush();
    this.albumClone = Object.assign([], this.album);

    // descending
    this.album = this.album.sort((a, b) => b.index - a.index);

    //ascending
    // this.album = this.album.sort((a, b) => a.index - b.index);

    //filter
    // this.cardsFilter('');

  }

  open(index: number): void {

    // this._lightbox.open(this.album, index);
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true });
  }

  close(): void {

    this._lightbox.close();
  }

  // cardsFilter(name) {
  //   // this.album = [];
  //   // this.cardsPush();
  //   this.album = this.albumClone.filter(s => s.type.includes(name));
  // }

  cardsPush() {

    this.album.push({
      'src': 'assets/img/work/main-page/1.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-1.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', 'index': 51
    });

    // Seylan Bank Personla Loan Campaign
    this.album.push({
      'src': 'assets/img/work/main-page/2.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-2.jpg',
      'type': 'page', 'link': '/works/seylan-bank-2', 'class': 'branding', "index": 2
    });

    //Seylan Bank Ran Naya
    this.album.push({
      'src': 'assets/img/work/main-page/3.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-3.jpg',
      'type': 'page', 'link': '/works/seylan-bank-3', 'class': 'branding', "index": 3
    });
    this.album.push({
      'src': 'assets/img/work/main-page/4.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-4.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 54
    });
    this.album.push({
      'src': 'assets/img/work/main-page/7.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-7.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 55
    });

    // Toyota lanka Campaign
    this.album.push({
      'src': 'assets/img/work/main-page/5.jpg', 'caption': 'Toyota Lanka Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-5.jpg',
      'type': 'page', 'link': '/works/toyoto-lanka', 'class': 'branding', "index": 6
    });


    // Seylan Bank Avurudu Campaign
    this.album.push({
      'src': 'assets/img/work/main-page/6.jpg', 'caption': 'Seylan Bank Avurudu Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-6.jpg',
      'type': 'page', 'link': '/works/seylan-bank', 'class': 'branding', "index": 7
    });

    this.album.push({
      'src': 'assets/img/work/main-page/8.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-8.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 58
    });

    // Foot Mania Ad Campaign
    this.album.push({
      'src': 'assets/img/work/foot-mania/1.jpg', 'caption': 'Foot Mania Ad Campaign', 'thumb': 'assets/img/work/foot-mania/thumbs/work-1.jpg',
      'type': 'page', 'link': '/works/foot-mania', 'class': 'branding', "index": 9
    });

    this.album.push({
      'src': 'assets/img/work/main-page/9.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-9.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 59
    });

    // Spa Ceylon Campaign
    this.album.push({
      'src': 'assets/img/work/main-page/10.jpg', 'caption': 'Spa Ceylon Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-10.jpg',
      'type': 'page', 'link': '/works/spa-ceylon', 'class': 'animation', "index": 11
    });


    // AMW Campaign
    this.album.push({
      'src': 'assets/img/work/main-page/11.jpg', 'caption': 'AMW Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-11.jpg',
      'type': 'page', 'link': '/works/amw', 'class': 'animation', "index": 13
    });

    // Spinner Campaign
    this.album.push({
      'src': 'assets/img/work/main-page/12.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-12.jpg',
      'type': 'page', 'link': '/works/spinner', 'class': 'digital', "index": 12
    });

    //Heros Of the Heart
    this.album.push({
      'src': 'assets/img/work/main-page/13.jpg', 'caption': 'Heros of the Heart Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-13.jpg',
      'type': 'page', 'link': '/works/seylan-bank-4', 'class': 'animation', "index": 13
    });

    // this.album.push({
    //   'src': 'assets/img/work/main-page/14.jpg', 'caption': 'Seylan Bank Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-14.jpg',
    //   'type': 'image', 'link': '/works/seylan-bank-4', 'class': 'branding', "index": 14
    // });

    this.album.push({
      'src': 'assets/img/work/main-page/15.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-15.jpg',
      'type': 'page', 'link': '/works/sinha', 'class': 'digital', "index": 15
    });

    this.album.push({
      'src': 'assets/img/work/main-page/16.jpg', 'caption': 'Seylan Mega Rewards Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-16.jpg',
      'type': 'page', 'link': '/works/seylan-bank-5', 'class': 'branding', "index": 16
    });


    this.album.push({
      'src': 'assets/img/work/main-page/17.jpg', 'caption': 'Seylan Tikiri Panel Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-17.jpg',
      'type': 'page', 'link': '/works/seylan-bank-6', 'class': 'branding', "index": 17
    });

    // this.album.push({
    //   'src': 'assets/img/work/main-page/18.jpg', 'caption': 'Seylan Bank TV Commercials', 'thumb': 'assets/img/work/main-page/thumbs/work-18.jpg',
    //   'type': 'page', 'link': '/works/seylan-bank-7', 'class': 'tv', "index": 18
    // });

    this.album.push({
      'src': 'assets/img/work/main-page/19.jpg', 'caption': 'Seylan Bank Quarterly E News Letter', 'thumb': 'assets/img/work/main-page/thumbs/work-19.jpg',
      'type': 'page', 'link': '/works/seylan-bank-8', 'class': 'news-letter', "index": 19
    });

    this.album.push({
      'src': 'assets/img/work/main-page/20.jpg', 'caption': 'Seylan Bank Rugby Logo', 'thumb': 'assets/img/work/main-page/thumbs/work-20.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'logo', "index": 20
    });

    this.album.push({
      'src': 'assets/img/work/main-page/21.jpg', 'caption': 'Seylan Bank Rugby Logo', 'thumb': 'assets/img/work/main-page/thumbs/work-21.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'logo', "index": 21
    });

    this.album.push({
      'src': 'assets/img/work/main-page/22.jpg', 'caption': 'Seylan Logo', 'thumb': 'assets/img/work/main-page/thumbs/work-22.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'logo', "index": 22
    });

    this.album.push({
      'src': 'assets/img/work/main-page/23.jpg', 'caption': 'Seylan Logo', 'thumb': 'assets/img/work/main-page/thumbs/work-23.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'logo', "index": 23
    });


    // Different Image Locations

    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/1.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-1.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=FacZnupW1mg', 'class': 'tv', "index": 24
    });


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/2.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-2.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=R8BzBe1pRQM', 'class': 'tv', "index": 25
    });


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/3.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-3.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Y17lsdmhoeg', 'class': 'tv', "index": 26
    });


    this.album.push({
      'src': 'assets/img/work/seylan-goald-loan/4.jpg', 'caption': 'Seylan TV Commercials Campaign', 'thumb': 'assets/img/work/seylan-goald-loan/thumbs/work-4.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=pKWSaOLcp3k', 'class': 'tv', "index": 27
    });


    this.album.push({
      'src': 'assets/img/work/spinner/7.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-7.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=daNqT1dojeQ', 'class': 'animation', "index": 28
    });

    this.album.push({
      'src': 'assets/img/work/spinner/8.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-8.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=yhPcmcEYvyE', 'class': 'animation', "index": 29
    });


    this.album.push({
      'src': 'assets/img/work/spinner/9.jpg', 'caption': 'Spinner Ad Campaign', 'thumb': 'assets/img/work/spinner/thumbs/work-9.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Gi4eEQozj1A', 'class': 'animation', "index": 30
    });

    this.album.push({
      'src': 'assets/img/work/sinha/17.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/sinha/thumbs/work-17.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=iMrcrKu4opM', 'class': 'animation', "index": 31
    });

    this.album.push({
      'src': 'assets/img/work/main-page/32.jpg', 'caption': 'Seylan SME Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-32.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=2oJmusYKi48', 'class': 'animation', "index": 32
    });


    this.album.push({
      'src': 'assets/img/work/main-page/33.jpg', 'caption': 'Seylan SMECampaign', 'thumb': 'assets/img/work/main-page/thumbs/work-33.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=qbNvlCDSDtY', 'class': 'animation', "index": 33
    });


    this.album.push({
      'src': 'assets/img/work/main-page/34.jpg', 'caption': 'Seylan SME Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-34.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=vJZJG4-bACs', 'class': 'animation', "index": 34
    });


    this.album.push({
      'src': 'assets/img/work/main-page/35.jpg', 'caption': 'Seylan SME Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-35.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Rbcp7mux-og', 'class': 'animation', "index": 35
    });



    this.album.push({
      'src': 'assets/img/work/main-page/36.jpg', 'caption': 'Singha Lanka Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-36.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Ont-A6SioOE', 'class': 'animation', "index": 36
    });

    this.album.push({
      'src': 'assets/img/work/main-page/37.jpg', 'caption': 'Seylan Tikiri', 'thumb': 'assets/img/work/main-page/thumbs/work-37.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=yXaI8WfEC3k', 'class': 'tv', "index": 37
    });


    this.album.push({
      'src': 'assets/img/work/main-page/38.jpg', 'caption': 'Seylan Golad Loan', 'thumb': 'assets/img/work/main-page/thumbs/work-38.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=o17chL8qShg', 'class': 'tv', "index": 38
    });

    this.album.push({
      'src': 'assets/img/work/main-page/39.jpg', 'caption': 'Seylan Bank', 'thumb': 'assets/img/work/main-page/thumbs/work-39.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 39
    });

    this.album.push({
      'src': 'assets/img/work/main-page/40.jpg', 'caption': 'Seylan Bank', 'thumb': 'assets/img/work/main-page/thumbs/work-40.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 39
    });

    this.album.push({
      'src': 'assets/img/work/main-page/41.jpg', 'caption': 'Seylan Bank', 'thumb': 'assets/img/work/main-page/thumbs/work-41.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 39
    });

    this.album.push({
      'src': 'assets/img/work/main-page/42.jpg', 'caption': 'Seylan Bank', 'thumb': 'assets/img/work/main-page/thumbs/work-42.jpg',
      'type': 'image', 'link': '/works/seylan-bank', 'class': 'branding', "index": 39
    });

    this.album.push({
      'src': 'assets/img/work/main-page/43.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-43.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=R3aYoISFz14', 'class': 'animation', "index": 40
    });

    this.album.push({
      'src': 'assets/img/work/main-page/44.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-44.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=vXfGO0bbZMk', 'class': 'animation', "index": 41
    });

    this.album.push({
      'src': 'assets/img/work/main-page/45.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-45.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=BnJi3MnH5P4', 'class': 'animation', "index": 42
    });

    this.album.push({
      'src': 'assets/img/work/main-page/46.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-46.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=VFrVoVL4w7c', 'class': 'animation', "index": 43
    });

    this.album.push({
      'src': 'assets/img/work/main-page/47.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-47.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=CtdCc-gkDy4', 'class': 'animation', "index": 44
    });

    this.album.push({
      'src': 'assets/img/work/main-page/48.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-48.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=xVqvlyfDqXQ', 'class': 'animation', "index": 45
    });

    this.album.push({
      'src': 'assets/img/work/main-page/49.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-49.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=8Djj5aJcsa8', 'class': 'animation', "index": 46
    });

    this.album.push({
      'src': 'assets/img/work/main-page/50.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-50.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=1FT8pK4x2j4', 'class': 'animation', "index": 47
    });

    this.album.push({
      'src': 'assets/img/work/main-page/51.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-51.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=fHFdamv9R5Y', 'class': 'animation', "index": 48
    });

    this.album.push({
      'src': 'assets/img/work/main-page/52.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-52.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=S9GmRrPtpKY', 'class': 'animation', "index": 49
    });



    this.album.push({
      'src': 'assets/img/work/main-page/53.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-53.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=5sdFaWUK3Tc', 'class': 'animation', "index": 60
    });


    this.album.push({
      'src': 'assets/img/work/main-page/54.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-54.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=PCo6KpHb-Yw', 'class': 'animation', "index": 61
    });


    this.album.push({
      'src': 'assets/img/work/main-page/55.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-55.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=8XNa8cW87u8', 'class': 'animation', "index": 62
    });

    this.album.push({
      'src': 'assets/img/work/main-page/56.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-56.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=KFcwxzzGNwg', 'class': 'animation', "index": 63
    });

    this.album.push({
      'src': 'assets/img/work/main-page/57.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-57.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=uHgIEnF-1Wk', 'class': 'animation', "index": 64
    });

    this.album.push({
      'src': 'assets/img/work/main-page/58.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-58.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=-u7Ucr2amzs', 'class': 'animation', "index": 65
    });

    this.album.push({
      'src': 'assets/img/work/main-page/59.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-59.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=_EfD-gTig-E', 'class': 'animation', "index": 66
    });

    this.album.push({
      'src': 'assets/img/work/main-page/60.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-60.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=LgkOczJ6PhU', 'class': 'animation', "index": 67
    });

    this.album.push({
      'src': 'assets/img/work/main-page/61.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-61.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=ALdxbXdy4Mc', 'class': 'animation', "index": 68
    });

    this.album.push({
      'src': 'assets/img/work/main-page/62.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-62.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=soYWjm7a4wE', 'class': 'animation', "index": 69
    });

    this.album.push({
      'src': 'assets/img/work/main-page/63.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-63.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=yFkYpsb19Ck', 'class': 'tv', "index": 70
    });

    this.album.push({
      'src': 'assets/img/work/main-page/64.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-64.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=MY6f8KC8Pms', 'class': 'tv', "index": 71
    });

    this.album.push({
      'src': 'assets/img/work/main-page/65.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-65.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=kq4yxlFjD-U', 'class': 'tv', "index": 72
    });

    this.album.push({
      'src': 'assets/img/work/main-page/66.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-66.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Vav0BZGJwC4', 'class': 'tv', "index": 73
    });

    this.album.push({
      'src': 'assets/img/work/main-page/67.jpg', 'caption': 'Seylan Bank App', 'thumb': 'assets/img/work/main-page/thumbs/work-67.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=qbIoJIVKM_U', 'class': 'tv', "index": 73
    });





    this.album.push({
      'src': 'assets/img/work/main-page/68.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-68.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 80
    });

    this.album.push({
      'src': 'assets/img/work/main-page/69.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-69.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 81
    });

    this.album.push({
      'src': 'assets/img/work/main-page/70.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-70.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 82
    });

    this.album.push({
      'src': 'assets/img/work/main-page/71.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-71.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 83
    });

    this.album.push({
      'src': 'assets/img/work/main-page/72.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-72.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 84
    });

    this.album.push({
      'src': 'assets/img/work/main-page/73.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-73.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 85
    });

    this.album.push({
      'src': 'assets/img/work/main-page/74.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-74.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 86
    });

    this.album.push({
      'src': 'assets/img/work/main-page/75.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-75.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 87
    });

    this.album.push({
      'src': 'assets/img/work/main-page/76.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-76.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 88
    });

    this.album.push({
      'src': 'assets/img/work/main-page/77.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-77.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 89
    });

    this.album.push({
      'src': 'assets/img/work/main-page/78.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-78.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': 90
    });

//Japan Poster Set Start //

    this.album.push({
      'src': 'assets/img/work/main-page/79.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-79.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': -1
    });

    this.album.push({
      'src': 'assets/img/work/main-page/80.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-80.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': -2
    });

    this.album.push({
      'src': 'assets/img/work/main-page/81.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-81.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': -3
    });

    this.album.push({
      'src': 'assets/img/work/main-page/82.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-82.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': -4
    });

    this.album.push({
      'src': 'assets/img/work/main-page/83.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-83.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': -5
    });

    this.album.push({
      'src': 'assets/img/work/main-page/84.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-84.jpg',
      'type': 'image', 'link': '/works', 'class': 'branding', 'index': -6
    });

    //Japan Poster Set END //

    this.album.push({
      'src': 'assets/img/work/main-page/85.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-85.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=Rmopr_3yX5Q', 'class': 'tv', "index": 97
    });

    this.album.push({
      'src': 'assets/img/work/main-page/86.jpg', 'caption': 'Ad Campaign', 'thumb': 'assets/img/work/main-page/thumbs/work-86.jpg',
      'type': 'video', 'link': 'https://www.youtube.com/watch?v=ZFisZP4AzR4', 'class': 'tv', "index": 98
    });

    

    this.album.push({
      'src': 'assets/img/work/main-page/87.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-87.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=30q6zEycekU',
      'class': 'animation',
      "index": 101
    });
    this.album.push({
      'src': 'assets/img/work/main-page/88.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-88.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=Q6afjiBdB7U',
      'class': 'animation',
      "index": 102
    });
    this.album.push({
      'src': 'assets/img/work/main-page/89.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-89.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=zKrHXJECHyU',
      'class': 'animation',
      "index": 103
    });
    this.album.push({
      'src': 'assets/img/work/main-page/90.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-90.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=osy23Op0J-A',
      'class': 'animation',
      "index": 104
    });
    this.album.push({
      'src': 'assets/img/work/main-page/91.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-91.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=rQ2XgoFNSKc',
      'class': 'animation',
      "index": 105
    });
    this.album.push({
      'src': 'assets/img/work/main-page/92.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-92.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=yBK1tTD3Fbc',
      'class': 'animation',
      "index": 106
    });
    this.album.push({
      'src': 'assets/img/work/main-page/93.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-93.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=luOLOpDz3nA',
      'class': 'animation',
      "index": 107
    });
    this.album.push({
      'src': 'assets/img/work/main-page/94.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-94.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=lHhjSDstDwo',
      'class': 'animation',
      "index": 108
    });



    // New Listing 2024-09-26

    this.album.push({
      'src': 'assets/img/work/main-page/95.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-95.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'digital',
      'index': 109
    });

    this.album.push({
      'src': 'assets/img/work/main-page/96.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-96.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'digital',
      'index': 110
    });

    this.album.push({
      'src': 'assets/img/work/main-page/97.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-97.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'digital',
      'index': 111
    });

    this.album.push({
      'src': 'assets/img/work/main-page/98.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-98.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 112
    });

    this.album.push({
      'src': 'assets/img/work/main-page/99.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-99.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 113
    });

    this.album.push({
      'src': 'assets/img/work/main-page/100.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-100.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 114
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/101.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-101.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 115
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/102.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-102.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 116
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/103.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-103.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 117
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/104.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-104.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 118
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/105.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-105.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 119
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/106.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-106.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 120
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/107.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-107.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 121
    });

    this.album.push({
      'src': 'assets/img/work/main-page/108.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-108.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 122
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/109.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-109.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 123
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/110.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-110.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 124
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/111.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-111.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 125
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/112.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-112.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 126
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/113.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-113.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 127
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/114.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-114.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 128
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/115.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-115.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 129
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/116.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-116.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 130
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/117.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-117.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 131
    });

    this.album.push({
      'src': 'assets/img/work/main-page/118.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-118.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 132
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/119.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-119.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 133
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/120.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-120.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 134
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/121.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-121.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 135
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/122.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-122.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 136
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/123.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-123.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 137
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/124.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-124.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 138
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/125.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-125.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 139
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/126.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-126.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 140
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/127.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-127.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 141
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/128.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-128.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 142
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/129.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-129.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 143
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/130.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-130.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 144
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/131.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-131.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 145
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/132.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-132.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 146
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/133.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-133.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 147
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/134.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-134.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 148
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/135.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-135.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 149
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/136.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-136.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 150
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/137.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-137.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 151
    });

    this.album.push({
      'src': 'assets/img/work/main-page/138.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-138.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 152
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/139.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-139.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 153
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/140.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-140.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 154
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/141.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-141.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 155
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/142.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-142.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 156
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/143.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-143.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 157
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/144.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-144.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 158
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/145.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-145.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 159
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/146.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-146.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 160
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/147.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-147.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 161
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/148.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-148.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 162
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/149.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-149.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 163
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/150.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-150.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 164
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/151.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-151.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 165
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/152.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-152.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 166
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/153.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-153.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 167
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/154.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-154.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 168
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/155.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-155.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 169
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/156.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-156.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 170
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/157.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-157.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 171
    });

    this.album.push({
      'src': 'assets/img/work/main-page/158.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-158.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 172
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/159.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-159.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 173
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/160.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-160.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 174
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/161.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-161.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 175
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/162.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-162.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 176
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/163.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-163.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 177
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/164.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-164.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 178
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/165.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-165.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 179
    });

    


    this.album.push({
      'src': 'assets/img/work/main-page/166.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-166.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=vI3QNenEOX4',
      'class': 'animation',
      'index': 180
    });
    this.album.push({
      'src': 'assets/img/work/main-page/167.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-167.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=BI5EnUO4A_4',
      'class': 'animation',
      'index': 181
    });
    this.album.push({
      'src': 'assets/img/work/main-page/168.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-168.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=OyVyhG8voHY',
      'class': 'animation',
      'index': 182
    });
    this.album.push({
      'src': 'assets/img/work/main-page/169.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-169.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=1AiZfuh7jY4',
      'class': 'animation',
      'index': 183
    });
    this.album.push({
      'src': 'assets/img/work/main-page/170.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-170.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=FO0lQ_cuVxE',
      'class': 'animation',
      'index': 184
    });
    this.album.push({
      'src': 'assets/img/work/main-page/171.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-171.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=fqzW_xY5JT0',
      'class': 'animation',
      'index': 185
    });
    this.album.push({
      'src': 'assets/img/work/main-page/172.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-172.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=rijKKaKtRpE',
      'class': 'animation',
      'index': 186
    });
    this.album.push({
      'src': 'assets/img/work/main-page/173.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-173.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=kEN4W5H1Atw',
      'class': 'animation',
      'index': 187
    });
    this.album.push({
      'src': 'assets/img/work/main-page/174.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-174.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/01-union-assuarance.pdf',
      'class': 'branding',
      'index': 188
    });
    this.album.push({
      'src': 'assets/img/work/main-page/175.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-175.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/02-seylan.pdf',
      'class': 'branding',
      'index': 189
    });
    this.album.push({
      'src': 'assets/img/work/main-page/176.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-176.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/03-seylan-remittance-desk-calendar- 2024.pdf',
      'class': 'branding',
      'index': 190
    });
    this.album.push({
      'src': 'assets/img/work/main-page/177.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-177.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/04-seylan-bethi-gee-harasara-2024.pdf',
      'class': 'branding',
      'index': 191
    });
    this.album.push({
      'src': 'assets/img/work/main-page/178.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-178.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/05-seylan-english-hit-ads.pdf',
      'class': 'branding',
      'index': 192
    });
    this.album.push({
      'src': 'assets/img/work/main-page/179.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-179.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/06-seylan-sinhala-lahipita.pdf',
      'class': 'branding',
      'index': 193
    });
    this.album.push({
      'src': 'assets/img/work/main-page/180.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-180.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/07-seylan-stall.pdf',
      'class': 'branding',
      'index': 194
    });
    this.album.push({
      'src': 'assets/img/work/main-page/181.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-181.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/08-seylan-stal.pdf',
      'class': 'branding',
      'index': 195
    });
    this.album.push({
      'src': 'assets/img/work/main-page/182.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-182.jpg',
      'type': 'pdf',
      'link': 'assets/img/work/main-page/pdf/09-seylan-stall.pdf',
      'class': 'branding',
      'index': 196
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/183.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-183.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 197
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/184.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-184.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 198
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/185.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-185.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 199
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/186.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-186.jpg',
      'type': 'image',
      'link': '/works',
      'class': 'branding',
      'index': 200
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/187.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-187.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=ukrkxdV61kU',
      'class': 'animation',
      'index': 201
    });

    this.album.push({
      'src': 'assets/img/work/main-page/188.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-188.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=rJ_CKGP_KSs',
      'class': 'animation',
      'index': 202
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/189.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-189.jpg',
      'type': 'video',
      'link': 'https://www.youtube.com/watch?v=7s0H5xvH-vk',
      'class': 'animation',
      'index': 203
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/190.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-190.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=b5LqvN11jDM',
      'class': 'animation',
      'index': 204
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/191.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-191.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=7UsvshwQ0Ew',
      'class': 'animation',
      'index': 205
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/192.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-192.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=EOXCFNfdwlc',
      'class': 'animation',
      'index': 206
    });

    this.album.push({
      'src': 'assets/img/work/main-page/193.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-193.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=DOcwHEtbiIQ',
      'class': 'animation',
      'index': 207
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/194.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-194.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=1NNMj4l7PlE',
      'class': 'animation',
      'index': 208
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/195.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-195.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=WsiYjiy6SYE',
      'class': 'animation',
      'index': 209
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/196.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-196.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=A6fgewI4img',
      'class': 'animation',
      'index': 210
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/197.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-197.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=JArKrC3vsvE',
      'class': 'animation',
      'index': 211
    });
    
    this.album.push({
      'src': 'assets/img/work/main-page/198.jpg',
      'caption': 'Ad Campaign',
      'thumb': 'assets/img/work/main-page/thumbs/work-198.jpg',
      'type': 'video',
      'link': 'https://youtube.com/watch?v=jPmjZXpIpqk',
      'class': 'animation',
      'index': 212
    });
    

  }

}
